<template>
  <!-- 页面头部查询模块 -->
  <suy-table v-model:columns="columns">
    <template #search>
      <a-form layout="inline" :v-model="state">
        <a-form-item label="">
          <a-input v-model:value="search.no" placeholder="编号查询" />
        </a-form-item>
        <a-form-item label="">
          <a-select v-model:value="search.answer.value" placeholder="分数查询" @change="searchScore" :options="scoreArr" :allowClear="true" />
        </a-form-item>
        <a-form-item label="">
          <a-input v-model:value="search.orderId" placeholder="订单查询" />
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="onSearch" :loading="loading">搜索 </a-button>
        </a-form-item>
      </a-form>
    </template>
    <!-- 表格主体 -->
    <a-table :columns="columns" :row-key="record => record.orderId" :data-source="data" bordered :pagination="pagination" :loading="loading" @change="handleTableChange">
      <template #answer="{ record }">
        <div>
          <span style="color:#2186E4">{{record.answer.label}}. {{record.answerLabel}}({{record.answer.value}}分)</span>
        </div>
      </template>
    </a-table>
  </suy-table>
</template>

<script>
import { onMounted, reactive, toRefs } from 'vue'
import SuyTable from '@/components/SuyTable'
import { evaluationPage } from '@/api/trans/order'

export default {
  components: {
    SuyTable
  },
  setup () {
    const state = reactive({
      loading: false,
      searchData1: '',
      searchData2: '',
      data: [],
      search: {
        no: '',
        orderId: '',
        answer: { value: null }
      },
      obj: {
        props: { rowSpan: 2 }
      },
      columns: [
        {
          title: '订单号',
          dataIndex: 'orderId'
        },
        {
          title: '提交答卷时间',
          dataIndex: 'time'
        },
        {
          title: '题目编号',
          dataIndex: 'no'
        },
        {
          title: '题目内容',
          dataIndex: 'title'
        },
        {
          title: '答案及文本',
          dataIndex: 'answer.label',
          slots: {
            customRender: 'answer'
          }
        }
      ],
      scoreArr: [
        {
          label: 'A',
          value: 4
        },
        {
          label: 'B',
          value: 3
        },
        {
          label: 'C',
          value: 2
        },
        {
          label: 'D',
          value: 1
        }
      ],
      pagination: {
        current: 1,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })

    const onSearch = () => {
      state.pagination.current = 1
      loadData()
    }
    const loadData = (again) => {
      state.loading = true
      evaluationPage({
        current: state.pagination.current,
        no: again !== 1 ? state.search.no : '',
        answer: again !== 1 ? Number(state.search.answer.value) : '',
        orderId: again !== 1 ? state.search.orderId : ''
      }).then(res => {
        if (res.code === 10000) {
          res.data.records.forEach(item => {
            for (const key in item) {
              if (item[key] === null) item[key] = ''
            }
          })
          state.data = res.data.records
          state.pagination.total = res.data.total
          state.loading = false
        }
      }).catch(err => console.log(err))
    }
    const handleTableChange = pag => {
      state.pagination.current = pag.current
      loadData()
    }
    const searchScore = val => {
      state.search.answer.value = val
    }
    onMounted(loadData)

    return {
      ...toRefs(state),
      handleTableChange,
      loadData,
      onSearch,
      searchScore
    }
  }
}
</script>

<style lang="less" scoped>
</style>
